<template>
<div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>

          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

              <div>
                <div>
                  <multiple-select :options="personList"  labelField="nome" :id-field="'id'" :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="personFetchOptions" :value="filters.filCliente" />
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                        {{ $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

<!--      <div class="col-12 mb-3">-->
<!--        <div class="card no-bottom-margin">-->
<!--          <div class="section-sub-menu">-->
<!--            <div class="section-sub-menu">-->
<!--              <ul class="nav line-tabs borderless flex-center">-->
<!--                <li class="nav-item">-->
<!--                  <a class="nav-link" v-bind:class="{ active: filter.id_status === null }"-->
<!--                     v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>-->
<!--                </li>-->
<!--                <li class="nav-item">-->
<!--                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 1 }"-->
<!--                     v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('form.movementType.status.active') }}</a>-->
<!--                </li>-->
<!--                <li class="nav-item">-->
<!--                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 2 }"-->
<!--                     v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('form.movementType.status.inactive')-->
<!--                    }}</a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <!--<div v-on:click="toggleColumn">Config</div>-->
                <div class="table-responsive">
                  <vuetable ref="vuetable"
                            detail-row-component="detail-row-tax"
                            @vuetable:cell-clicked="onCellClicked"
                            :noDataTemplate="$t('noting to result')"
                            :api-url="processEndpoint"
                            :fields="fieldsTable"
                            :sort-order="sortOrder"
                            :css="css.table"
                            pagination-path="pagination"
                            :per-page="20"
                            :multi-sort="multiSort"
                            :append-params="filter"
                            multi-sort-key="shift"
                            :http-options="httpOptions"
                            track-by="id"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            @vuetable:load-error="handleLoadError">
                    <template slot="personDefault" slot-scope="props">
                      <v-select
                        label="personDefault"
                        class="md-form-control"
                        :clearable="true"
                        :searchable="true"
                        :options="personTypeList"
                        v-model="props.rowData.id_tomador_nota_padrao"
                        @input="onSelectPersonType(props.rowData)">
                        <template slot="no-options">
                          {{ 'Nenhum resultado encontrado' }}
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.codigo }} - {{ option.nome }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.codigo }} - {{ option.nome }}
                          </div>
                        </template>
                      </v-select>
                    </template>
                    <template slot="movementType" slot-scope="props">
                      <v-select
                        label="movementType"
                        class="md-form-control"
                        :clearable="true"
                        :searchable="true"
                        :options="movementTypeList"
                        v-model="props.rowData.tipo_movimentacao_padrao"
                        @input="onSelectMovementType(props.rowData)">
                        <template slot="no-options">
                          {{ 'Nenhum resultado encontrado' }}
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                    </template>
                    <!--                    -->
                    <template slot="personDefaultRod" slot-scope="props">
                      <v-select
                        label="personDefaultRod"
                        class="md-form-control"
                        :clearable="true"
                        :searchable="true"
                        :options="personTypeList"
                        v-model="props.rowData.id_tomador_nota_padrao_rod"
                        @input="onSelectPersonRodType(props.rowData)">
                        <template slot="no-options">
                          {{ 'Nenhum resultado encontrado' }}
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.codigo }} - {{ option.nome }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.codigo }} - {{ option.nome }}
                          </div>
                        </template>
                      </v-select>
                    </template>
                    <template slot="movementTypeRod" slot-scope="props">
                      <v-select
                        label="movementTypeRod"
                        class="md-form-control"
                        :clearable="true"
                        :searchable="true"
                        :options="movementTypeList"
                        v-model="props.rowData.tipo_movimentacao_padrao_rod"
                        @input="onSelectMovementRodType(props.rowData)">
                        <template slot="no-options">
                          {{ 'Nenhum resultado encontrado' }}
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                    </template>
                    <!--                    -->
                    <template slot="taxa_preferida" slot-scope="props">
                      <custom-switch
                        :listItems="[{text: '', value: true}]"
                        v-model="props.rowData.taxa_preferida"
                        name="movementType.taxa_preferida"
                        type="checkbox"
                        rootClassName="col-lg-12"
                        stateClassName="p-primary"
                        @input="onSelectIsTaxPrefered(props.rowData)"
                        :error="errors.first('movementType.taxa_preferida')">
                      </custom-switch>
                    </template>
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                                     :css="css.pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
</div>
</template>

<script>
import Vue from 'vue'
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Components

// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'

// Get services
import MovementTypeService from '@/services/MovementTypeService'
import PersonTypeService from '@/services/PersonTypeService'
import PersonService from '@/services/PersonService'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

import DetailRowTax from './DetailRowTax.vue'
Vue.component('detail-row-tax', DetailRowTax)

export default {
  name: 'movementTypeList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('invoice.preferenceInvoice.title') + ' - %s'
    }
  },
  data () {
    return {
      personTypeSelected: {},
      personTypeList: [],
      movementTypeSelected: {},
      movementTypeList: [],
      personList: [],
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/pessoas',
      multiSort: true,
      activeFilterList: {
        customer: null,
        id_status: null
      },
      filter: {},
      sortOrder: [
        { field: 'u.ativo', direction: 'asc' },
        { field: 'u.nome', direction: 'asc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    Vuetable,
    VuetablePagination,
    Loading,
    FilterContainer,
    MultipleSelect,
    vSelect,
    CustomSwitch
  },
  // Apply filters to local filter
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)
    this.movementTypeFetch()
    this.personTypesFetch()

    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    onCellClicked ({ data, field, event }) {
      if (data.tipo_movimentacao_padrao) {
        this.$refs.vuetable.toggleDetailRow(data.id)
      } else {
        this.$alertSwal.modalAlert('Você deve selecionar um tipo de movimentação.')
      }
    },
    movementTypeFetch () {
      MovementTypeService.getMovementTypes({ type: 1 }, 1, 1000).then(res => {
        this.movementTypeList = res.data.data
      })
    },
    personTypesFetch () {
      PersonTypeService.getPersonTypes({}, 1, 1000).then(res => {
        this.personTypeList = res.data.data
      })
    },
    onSelectIsTaxPrefered (val) {
      PersonService.putIsTaxPrefered(val.id, val.taxa_preferida).then(res => {

      })
    },
    onSelectMovementType (val) {
      if (val.tipo_movimentacao_padrao && val.tipo_movimentacao_padrao.id_movement_type) {
        PersonService.putMovementType(val.id, val.tipo_movimentacao_padrao.id_movement_type).then(res => {
        })
      } else {
        PersonService.putMovementType(val.id, null).then(res => {
        })
      }
    },
    onSelectPersonType (val) {
      if (val.id_tomador_nota_padrao && val.id_tomador_nota_padrao.id) {
        PersonService.putPersonDefault(val.id, val.id_tomador_nota_padrao.id).then(res => {
        })
      } else {
        PersonService.putPersonDefault(val.id, null).then(res => {
        })
      }
    },
    onSelectMovementRodType (val) {
      if (val.tipo_movimentacao_padrao_rod && val.tipo_movimentacao_padrao_rod.id_movement_type) {
        PersonService.putMovementRodType(val.id, val.tipo_movimentacao_padrao_rod.id_movement_type).then(res => {
        })
      } else {
        PersonService.putMovementRodType(val.id, null).then(res => {
        })
      }
    },
    onSelectPersonRodType (val) {
      if (val.id_tomador_nota_padrao_rod && val.id_tomador_nota_padrao_rod.id) {
        PersonService.putPersonRodDefault(val.id, val.id_tomador_nota_padrao_rod.id).then(res => {
        })
      } else {
        PersonService.putPersonRodDefault(val.id, null).then(res => {
        })
      }
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    personFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        PersonService.getPersons(filters).then(res => {
          _this.personList = res.data.data
          resolve(_this.personList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_schedule: object.id } }).href
    },
    buildFilters () {
      // teste
      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
      this.filter.id_status = null
    },
    handleLoadError (response) {
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.vuetable.refresh()
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()
    },
    // Loader
    applyFilter () {
      this.personList = []

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()

      this.filters.filCliente = []
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.vuetable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    }
  },
  computed: {
    fieldsTable () {
      return [
        {
          name: 'codigo',
          title: this.$i18n.t('form.billingPrefenrece.codigo'),
          sortField: 'u.codigo',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('form.billingPrefenrece.nome'),
          sortField: 'u.nome',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'personDefault',
          title: this.$i18n.t('form.billingPrefenrece.personDefault'),
          sortField: 'idTomadorNotaPadrao',
          dataClass: 'text-center',
          titleClass: 'text-center'
        },
        {
          name: 'movementType',
          title: this.$i18n.t('form.billingPrefenrece.movementType'),
          sortField: 'tipoMovimentacaoPadrao',
          dataClass: 'text-center',
          titleClass: 'text-center'
        },
        {
          name: 'personDefaultRod',
          title: this.$i18n.t('form.billingPrefenrece.personDefaultRod'),
          sortField: 'idTomadorNotaPadraoRod',
          dataClass: 'text-center',
          titleClass: 'text-center'
        },
        {
          name: 'movementTypeRod',
          title: this.$i18n.t('form.billingPrefenrece.movementTypeRod'),
          sortField: 'tipoMovimentacaoPadraoRod',
          dataClass: 'text-center',
          titleClass: 'text-center'
        }
        /*,
        {
          name: 'taxa_preferida',
          title: this.$i18n.t('form.billingPrefenrece.isTaxPrefered'),
          sortField: 'taxa_preferida',
          dataClass: 'text-center',
          titleClass: 'text-center'
        }
         */
      ]
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>

</style>
