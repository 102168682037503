<template>
   <div class="row justify-content-md-center">
      <div class="col-12 mb-3">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.movementType.taxes') }}</h5>
            <br />
            <multiselect
                v-model="rowData.taxes"
                :disabled="!rowData.taxa_preferida"
                :multiple="true"
                :max="1000"
                track-by="id"
                label="name"
                :allow-empty="true"
                placeholder="Selecione uma ou mais taxas"
                :close-on-select="false"
                :clear-on-select="false"
                :custom-label="customLabel"
                :options="taxesList"
                @input="onSelectTaxes"
                :show-labels="false">
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong>{{ option.idtaxa_logistica_exibicao || option.idtaxaLogisticaExibicao }}</strong> - <strong>{{ option.nome }}</strong>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <strong>{{ option.idtaxa_logistica_exibicao || option.idtaxaLogisticaExibicao }}</strong> - <strong>{{ option.nome }}</strong>
                </template>
              </multiselect>
          </div>
        </div>
      </div>
   </div>
</template>

<script>
/* eslint-disable camelcase */
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

import TaxLogisticService from '@/services/TaxLogisticService'
import PersonService from '@/services/PersonService'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  components: {
    Multiselect
  },
  data: function () {
    return {
      isDisableChange: true,
      idTaxes: [],
      taxesList: []
    }
  },
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    },
    options: {
      type: Object
    }
  },
  watch: {
    rowIndex: function (value, oldValue) {
    },
    rowData: function (value, oldValue) {
      if (this.rowData.taxa_preferida === 'true') {
        this.isDisableChange = false
      }
    }
  },
  mounted () {
    if (this.rowData.taxa_preferida === 'true') {
      this.isDisableChange = false
    }
    this.getAllTaxes()
    if (this.rowData.tipo_movimentacao_padrao && this.rowData.tipo_movimentacao_padrao.taxes) {
      this.rowData.taxes = this.rowData.tipo_movimentacao_padrao.taxes
    } else {
      this.rowData.taxes = []
    }
  },
  methods: {
    onSelectTaxes (value) {
      PersonService.putPersonTaxes(this.rowData.id, this.rowData.taxes).then(res => {
      })
    },
    customLabel ({ idtaxaLogisticaExibicao, idtaxa_logistica_exibicao, nome }) {
      return `${idtaxa_logistica_exibicao || idtaxaLogisticaExibicao} – ${nome}`
    },
    getAllTaxes () {
      TaxLogisticService.getTaxs({}, 1, 1000).then(res => {
        this.taxesList = res.data.data
      })
    },
    onClick (event) {
    }
  }
}
</script>
